<template>
  <div
    class="main-layout scenario-evaluation"
    v-bind:style="{
      background:
        'linear-gradient(180deg, rgba(255,255,255,0), rgba(255, 255,255,1) 20%), url(\'/images/background/' +
        scenario.color +
        '.jpg\') no-repeat',
    }"
  >
    <div class="usermenu left" v-if="game">
      <a href="#" class="link" @mousedown.prevent="back">Back</a>
    </div>
    <div id="details-main">
      <h1>{{ title }}</h1>
      <h4 class="label">{{ scenario.title }}</h4>

      <div v-if="evaluation" class="evaluation-score">
        <div class="description" v-html="evaluation.generateText()"></div>
        <div class="scores">
          <div class="score">
            <h4>Overall score</h4>
            <h1>{{ evaluation.overallScore() }}</h1>
          </div>
          <div class="economic-score" v-if="economicScore">
            <h4>Economic score</h4>
            <h1>{{ evaluation.evaluation(economicScore) }} €</h1>
          </div>
        </div>
      </div>

      <div class="map-wrapper">
        <map-widget
          :width="890"
          :height="400"
          ref="map"
          :id="'results-map'"
          :boundaries="this.solution.scenario.site.boundaries"
          :defaultZoom="13"
          :editable="false"
          :objectTypes="mapObjectTypes"
          :displayOnly="displayOnly"
          :showDisplayOnlyIcons="true"
          :existing="solution.scenario.site.trees"
          :rotate="solution.scenario.rotate || 0"
        />
      </div>

      <div v-if="evaluation && keyIndicators">
        <h3>Main indicators</h3>

        <div class="indicators">
          <div
            :class="'indicator ' + indicator"
            v-for="indicator in keyIndicators"
            v-bind:key="indicator"
          >
            <div class="heading">
              <h4>{{ indicators[indicator].name }}</h4>
              <div class="value">
                {{ evaluation.evaluation(indicator) }}
              </div>
            </div>

            <div class="charts">
              <line-chart
                :class="'chart'"
                :ref="'chart' + indicator"
                :chart-data="evaluation.datacollection(indicator)"
                :options="evaluation.chartOptions(indicator, true)"
                :width="425"
                :height="205"
              ></line-chart>
            </div>
          </div>
        </div>
      </div>

      <h3>Stakeholder satisfaction</h3>

      <div class="satisfaction progress-bar-widget" v-if="satisfaction">
        <div :class="s" v-for="(s, index) in satisfaction" v-bind:key="index">
          <h5>{{ globalIndicators[s].name }}</h5>
          <div class="numbers">
            <div class="value">{{ evaluation.satisfaction(s) }}</div>
            <div class="max">{{ evaluation.max(s) }}</div>
          </div>
          <div class="progress-wrapper">
            <div class="progress-bar" v-bind:style="indicatorLineStyle(s)" />
          </div>
        </div>
      </div>

      <div v-if="evaluation && additionalImpacts.length > 0">
        <h3>Additional impacts</h3>
        <div class="indicators">
          <div
            :class="'indicator ' + indicator"
            v-for="indicator in additionalImpacts"
            v-bind:key="indicator"
          >
            <div class="heading">
              <h4>{{ indicators[indicator].name }}</h4>
              <div class="value">
                {{ evaluation.evaluation(indicator) }}
              </div>
            </div>

            <div class="charts">
              <line-chart
                :class="'chart'"
                :ref="'chart' + indicator"
                :chart-data="evaluation.datacollection(indicator)"
                :options="evaluation.chartOptions(indicator, true, true)"
                :width="425"
                :height="205"
              ></line-chart>
            </div>
          </div>
        </div>
      </div>

      <h3>Add your extra notes</h3>

      <div class="notes">
        <form @submit.prevent>
          <textarea @input="addNote"></textarea>
        </form>
      </div>

      <div class="methodology">
        To read more about the impact calculation methodology,
        <a
          href="https://drive.google.com/file/d/1fla3J9FJV0C5Y3z-NRlhPOCSp6UiV6MP/view?usp=sharing"
          target="_blank"
          >click here</a
        >.
      </div>

      <div class="search">
        <h3>Would you like to learn about other NBS projects?</h3>
        <div class="content">
          The Urban Nature Atlas contains details on 1000 hand-picked
          Nature-Based Solutions from across 100 European cities.
        </div>
        <div class="link">
          <a class="link" href="https://www.una.city" target="_blank">Search</a>
        </div>
      </div>

      <div class="shareable-link" v-if="game && link">
        Your solution is published at <br />
        <a :href="link" target="_blank">{{ link }}</a
        >.
      </div>

      <div class="controls" v-if="game">
        <button class="white" @mousedown.prevent="back">Back</button>
        <button @mousedown.prevent="share" v-if="!solution.published">
          Share
        </button>
      </div>
    </div>

    <main-layout></main-layout>
  </div>
</template>

<script>
import MainLayout from "../components/MainLayout";
import MapWidget from "../components/MapWidget";
import Evaluation from "../evaluation";
import LineChart from "../components/lineChart";

export default {
  name: "EvaluationWidget",
  props: {
    solution: Object,
    workshop: Object,
    title: { type: String, default: "NBS impacts dashboard" },
    game: { type: Boolean, default: false },
  },
  components: { MainLayout, LineChart, MapWidget },
  data() {
    const additionalImpacts = [];
    const indicators = {};

    const keyIndicators =
      this.solution.scenario.keyIndicators &&
      this.solution.scenario.keyIndicators.length > 0
        ? this.solution.scenario.keyIndicators
        : Object.values(this.solution.scenario.indicators)
            .filter((i) => {
              const indicator = this.workshop.indicators.find(function (ind) {
                return ind._id == i._id;
              });
              return !indicator.economicScore;
            })
            .map((i) => {
              return i._id;
            });

    this.workshop.indicators.forEach((i) => {
      const k = i._id;
      indicators[k] = i;
      if (keyIndicators.indexOf(k) == -1 && !i.economicScore) {
        additionalImpacts.push(k);
      }
    });

    return {
      satisfaction: null,
      additionalImpacts: additionalImpacts,
      keyIndicators: keyIndicators,
      mapObjectTypes: {},
      indicators: indicators,
      displayOnly: { type: "FeatureCollection", features: [] },
      globalIndicators: {},
      evaluation: null,
      scenario: this.solution.scenario,
      mapId: "map-site-" + this.solution.scenario._id,
      siteZoom: 13,
      impactLabels: {
        "Environmental impact": [
          "cooling",
          "peak_flow_reduction",
          "noise",
          "carbon",
          "pollination",
        ],
        "Social impact": [
          "health",
          "life_satisfaction",
          "community",
          "economic_score",
        ],
      },
      link: this.solution.published
        ? window.location.origin + "/#/Solution/" + this.solution._id
        : false,
    };
  },

  created: function () {
    this.$store.dispatch("loadIndicators").then((globalIndicators) => {
      this.$store.dispatch("loadNbsCategories").then((categories) => {
        this.evaluation = new Evaluation(
          this.solution,
          this.workshop.nbsTypes,
          Object.values(this.indicators),
          categories
        );

        this.satisfaction = Object.values(globalIndicators)
          .filter((i) => {
            return i.label == "satisfaction";
          })
          .map((i) => {
            return i._id;
          });
        this.globalIndicators = globalIndicators;
        const economicScore = Object.values(this.evaluation.indicators).find(
          (i) => {
            return i.economicScore;
          }
        );
        this.economicScore = economicScore ? economicScore._id : false;
      });
    });
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$refs.map) {
        this.$refs.map.map.on("load", () => {
          for (const k in this.workshop.nbsTypes) {
            if (
              this.workshop.nbsTypes.hasOwnProperty(k) &&
              this.workshop.nbsTypes[k].object &&
              this.solution.scenario.nbsTypes &&
              this.solution.scenario.nbsTypes.indexOf(k) > -1
            ) {
              this.mapObjectTypes[k] = this.workshop.nbsTypes[k];
            }
          }
          this.displayOnly = this.solution.implementations;
        });
      }
    });
  },

  methods: {
    indicatorLineStyle(indicator) {
      const b = this.evaluation.resultPercentage(indicator);

      const d = { width: `${b}%` };
      if (b > 60) {
        d["border-color"] = "#33AC5F";
      } else if (b < 25) {
        d["border-color"] = "#EB621C";
      } else {
        d["border-color"] = "#F2CA30";
      }
      return d;
    },
    back() {
      this.$store.commit("setCurrentPage", "/ImplementationGame");
      this.$router.push({
        path: "/ImplementationGame",
      });
    },
    startOver() {
      this.$store.commit("setScenario", null);
      this.$store.commit("setCurrentPage", "/SelectScenario");
      this.$router.push({
        path: "/SelectScenario",
      });
    },
    share() {
      this.solution.published = true;
      this.$store.dispatch("saveSolution", this.solution).then(() => {
        this.link = window.location.origin + "/#/Solution/" + this.solution._id;
      });
    },
    addNote(value) {
      const solution = this.solution;
      solution.notes = value;
      this.$store.dispatch("saveSolution", solution);
    },
  },
};
</script>

<style lang="scss">
@import "../styles/base.scss";
@import "../styles/indicators.scss";

.scenario-evaluation {
  #details-main {
    max-width: 890px;
    margin: 0px auto auto;
    padding-top: 60px;
  }
  h4.label {
    text-align: center;
  }

  .scenario-details {
    .description,
    .target_description {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      /* or 171% */

      color: rgba(43, 77, 55, 0.7);
    }
  }

  .economic-score {
    margin-top: 20px;
    height: 70px;
    min-width: 220px;
    border-radius: 70px;
    padding: 11px 25px 0 25px;
    background: #f2d039;
    color: white;
  }
}
.evaluation-score {
  padding: 30px;
  background: #d6eedf;
  border-radius: 15px;
  font-family: Open Sans;
  /* or 171% */
  text-align: center;

  display: flex;
  align-items: center;
  margin-top: 60px;

  .description {
    font-size: 14px;
    line-height: 24px;
    margin-right: 47px;
  }
}

.shareable-link {
  font-family: "Open Sans";
  text-align: center;
  font-size: 16px;
}

.score {
  padding: 11px 25px 0 25px;
  color: #ffffff;
  height: 70px;
  border-radius: 48px;
  background: #33ac5f;
  min-width: 220px;
}

.score h4,
.scenario-evaluation .economic-score h4 {
  font-weight: normal;
  font-size: 10px;
  line-height: 22px;
  margin: 0;
}
.score h1,
.scenario-evaluation .economic-score h1 {
  line-height: 32px;
  margin: 0;
}

.indicators {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.indicator .heading {
  display: flex;
  justify-content: space-between;
}

.indicator h4 {
  @include futura-normal;
  font-size: 18px;
  line-height: 32px;
}
.indicator .value {
  @include futura-bold;
  font-size: 18px;
  line-height: 32px;
}
.scenario-evaluation h4.label {
  margin: 15px;
  text-align: left;
}

.impact-label {
  margin-bottom: 20px;
}

.satisfaction > div {
  flex-basis: 30%;
  margin: auto;
}
.satisfaction {
  display: flex;
}

// .peopleVisiting .chart:before {
// 	width: 345px;
// }

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
}
.scenario-evaluation button {
  line-height: 24px;
  padding: 14px 40px;
  margin: 0 10px;
}
button.white {
  background: white;
  color: #eb621c;
  border: solid 1px #eb621c;
}

.scenario-evaluation .chart {
  margin-left: -25px;
  margin-right: -10px;
}

.scenario-evaluation h3 {
  @include futura-bold;

  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-transform: uppercase;
  text-align: center;
  margin: 60px 0 40px 0;
}

.scenario-evaluation h4.label {
  text-align: center;
}

.notes textarea {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
  height: 200px;
  width: 100%;
}

.methodology {
  margin: 30px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* copy / dark */

  color: #2b4d37;
}

.search {
  background: #d6eedf;
  background: #d6eedf;
  border: 2px solid #d6eedf;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px;
  font-size: 16px;
  line-height: 24px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  /* or 150% */
  text-align: center;
  margin-bottom: 30px;
}
.search h3 {
  margin: 0;
}
.search .content {
  color: #2b4d37;
  margin: 15px 0;
}
.scenario-evaluation .controls {
  margin-top: 100px;
}
.scenario-evaluation .map-wrapper {
  margin-top: 30px;
}
</style>
